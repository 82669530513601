// Dark Mode
export const GET_THEME = 'GET_THEME';
export const UPDATE_THEME = 'UPDATE_THEME';
export const SET_DARK_MODE = 'SET_DARK_MODE';
export const SET_LIGHT_MODE = 'SET_LIGHT_MODE';

// Login user
export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const RESET_AUTH = 'RESET_AUTH';
export const RESET_AUTH_SUCCESS = 'RESET_AUTH_SUCCESS';

// Signup user
export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';

// Modal
export const TOGGLE_CODE_MODAL = 'TOGGLE_CODE_MODAL';
export const SHOW_CODE_MODAL = 'SHOW_CODE_MODAL';
export const GET_CODE = 'GET_CODE';
export const GET_CODE_SUCCESS = 'GET_CODE_SUCCESS';
export const FETCH_CODE = 'FETCH_CODE';
export const FETCH_CODE_SUCCESS = 'FETCH_CODE_SUCCESS';
export const FETCH_CODE_ERROR = 'FETCH_CODE_ERROR';
export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';

export const CLOSE_CODE_MODAL = 'CLOSE_CODE_MODAL';
export const CLOSE_CODE_MODAL_SUCCESS = 'CLOSE_CODE_MODAL_SUCCESS';

// Others
export const SHOW_FETCH_LOADING = 'SHOW_FETCH_LOADING';
export const HIDE_FETCH_LOADING = 'HIDE_FETCH_LOADING';
export const GET_QUESTIONS = 'GET_QUESTIONS';
export const GET_ALL_QUESTIONS = 'GET_ALL_QUESTIONS';
export const GET_TOP_QUESTIONS = 'GET_TOP_QUESTIONS';
export const GET_TODAY_QUESTIONS = 'GET_TODAY_QUESTIONS';
export const GET_SOLVED_QUESTIONS = 'GET_SOLVED_QUESTIONS';
export const MARK_QUESTION_AS_DONE = 'MARK_QUESTION_AS_DONE';
export const SET_QUESTION = 'SET_QUESTION';
export const SET_QUESTION_SUCCESS = 'SET_QUESTION_SUCCESS';
